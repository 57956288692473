// v4 causes dynamic image imports to be objects: https://github.com/facebook/create-react-app/issues/9992
export default {
  bg: require('../assets/images/core-bg.jpg')?.default,
  obs: require('../assets/images/onboarding_empty.svg')?.default,
  onb: require('../assets/images/onboarding_empty.png')?.default,
  pv: require('../assets/images/password-reset.png')?.default,
  ev: require('../assets/images/email-verification.jpg')?.default,
  mev: require('../assets/images/mobile-email-verification.png')?.default,
  spl: require('../assets/images/splash-screen.png')?.default,
  mspl: require('../assets/images/mobile-splash-screen.png')?.default,
  bstck: require('../assets/images/bloodstock.png')?.default,
  cpstck: require('../assets/images/cape-throughbreed.png')?.default,
  capeRacing: require('../assets/images/cape-racing.png')?.default,
  tbrdstck: require('../assets/images/thoroughbred.png')?.default,
  sovtechLogo: require('../assets/images/sovtech-small.png')?.default,
  404: require('../assets/images/404.svg')?.default,
  noData: require('../assets/images/no-data.png')?.default,
  newsIcon: require('../assets/images/news-icon.svg')?.default,
  cashIcon: require('../assets/images/cash-icon.svg')?.default,
  flagIcon: require('../assets/images/flag-icon.svg')?.default,
  lockIcon: require('../assets/images/lock-icon.svg')?.default,
  playIcon: require('../assets/images/play-icon.svg')?.default,
  statsIcon: require('../assets/images/stats-icon.svg')?.default,
  reOrder: require('../assets/images/re-order.svg')?.default,
  gallopLogo: require('../assets/svg/Gallop.svg')?.default,
  gallopLogin: require('../assets/images/login-gallop.png')?.default,
  jockeyHelmet: require('../assets/images/jockey-helmet.svg')?.default,
  owner: require('../assets/images/owner-bag.svg')?.default,
  punter: require('../assets/images/punter-coin.svg')?.default,
  generalUser: require('../assets/images/general-user.svg')?.default,
  trainer: require('../assets/images/horse-jump.svg')?.default,
  userType: require('../assets/images/user-type.png')?.default,
  backArrow: require('../assets/images/back-arrow.svg')?.default,
  bookmark: require('../assets/images/bookmark.svg')?.default,
  shareSocial: require('../assets/images/share-social.svg')?.default,
  folder: require('../assets/images/folder.svg')?.default,
  forward: require('../assets/images/forward.svg')?.default,
  outline: require('../assets/images/outline.svg')?.default,
  closeModal: require('../assets/images/modal-close.svg')?.default,
  inviteModal: require('../assets/images/invite-email-modal.png')?.default,
  notification: require('../assets/images/notification.svg')?.default,
  profile: require('../assets/images/profile.svg')?.default,
  how2Bet: require('../assets/images/how-to-bet.png')?.default,
  tabGold: require('../assets/images/tab-gold.png')?.default,
  trackNball: require('../assets/images/track-n-ball.png')?.default,
  readRaceCard: require('../assets/images/read-race-card.svg')?.default,
  raceOfficial: require('../assets/images/racing-official.png')?.default,
  nhra: require('../assets/images/nhra.png')?.default,
  nris: require('../assets/images/nris.png')?.default,
  raceDownload: require('../assets/images/race-download.png')?.default,
  raceTrainer: require('../assets/images/racing-trainer.png')?.default,
  computForm: require('../assets/images/comput-form.png')?.default,
  facebook: require('../assets/images/facebookLogo.png')?.default,
  twitter: require('../assets/images/twitterLogo.png')?.default,
  instagram: require('../assets/images/instagramLogo.png')?.default,
  youtube: require('../assets/images/youtubeLogo.png')?.default,
  greyVille: require('../assets/images/grey-ville.jpeg')?.default,
  scottsVille: require('../assets/images/scotts-ville.jpeg')?.default,
  bettingNews: require('../assets/images/betting-news.svg')?.default,
  breedingNews: require('../assets/images/breeding-news.svg')?.default,
  regulatoryNews: require('../assets/images/regulatory-news.svg')?.default,
  internationalNews: require('../assets/images/international-news.svg')?.default,
  eventNews: require('../assets/images/event-news.svg')?.default,
  saHorseIcon: require('../assets/images/horseshoe.svg')?.default,
  bredIcon: require('../assets/images/horse.svg')?.default,
  fixtureIcon: require('../assets/images/calendar-blank.svg')?.default,
  defaultNewsImage: require('../assets/images/default-image.jpg')?.default,
  galloptvIcon: require('../assets/images/smart-tv.svg')?.default,
  faqIcon: require('../assets/images/faq.svg')?.default,
  stripeIcon: require('../assets/images/stripe-stats.svg')?.default,
  eventsIcon: require('../assets/images/events.svg').default,
  termsIcon: require('../assets/images/terms-and-conditions.svg').default,
  gallopLogoWhite: require('../assets/images/gallopLogo.png')?.default,
  gallopHorseBG: require('../assets/svg/gallopHorseBG.svg')?.default,
  gallopHorseLogo: require('../assets/svg/gallopHorseLogo.svg')?.default,
  notFoundPage: require('../assets/images/notFoundPage.png')?.default,
  gallopIntro: require('../assets/gallopIntro.mov')?.default,
  gallopMobileIntro: require('../assets/gallopMobileIntro.mov')?.default,
  outLine: require('../assets/images/outline.png')?.default,
  media: require('../assets/images/media.png')?.default,
  defaultCard: require('../assets/images/defaultCard.png')?.default,
  newsIconBlack: require('../assets/images/news-icon-black.svg')?.default,
  bellIcon: require('../assets/images/bell.svg')?.default,
  worldIcon: require('../assets/images/world-icon.svg')?.default,
  recordIcon: require('../assets/images/record.svg')?.default,
  monitorIcon: require('../assets/images/monitor.svg')?.default,
  userCheckIcon: require('../assets/images/user-check.svg')?.default,
  coinsIcon: require('../assets/images/coins.svg')?.default,
  mapIcon: require('../assets/images/map.svg')?.default,
  horseRiding: require('../assets/images/horse-riding.png')?.default,
  horseRacing: require('../assets/images/horse-riding-2.jpeg')?.default,
  facebookIcon: require('../assets/social-icons/facebook.svg')?.default,
  twitterIcon: require('../assets/social-icons/twitter.svg')?.default,
  messengerIcon: require('../assets/social-icons/messenger.svg')?.default,
  whatsappIcon: require('../assets/social-icons/whatsApp.svg')?.default,
  redditIcon: require('../assets/social-icons/reddit.svg')?.default,
  linkedInIcon: require('../assets/social-icons/linkedIn.svg')?.default,
  gmailIcon: require('../assets/social-icons/gmail.svg')?.default,
  telegramIcon: require('../assets/social-icons/telegram.svg')?.default,
  viberIcon: require('../assets/social-icons/viber.svg')?.default,
  tumblerIcon: require('../assets/social-icons/tumbler.svg')?.default,
  okIcon: require('../assets/social-icons/ok.svg')?.default,
  crs: require('../assets/images/crs.jpg')?.default,
  bsa: require('../assets/images/bsa.jpg')?.default,
  tba: require('../assets/images/tba.jpg')?.default,
  tabGoldLogo: require('../assets/images/tabGoldLogo.png')?.default,
  trackandBallLogo: require('../assets/images/trackandBallLogo.png')?.default,
  hwbGreyvilleLogo: require('../assets/images/HWG.jpg')?.default,
  hwbScottsvilleLogo: require('../assets/images/HWS.jpg')?.default,
  infoIcon: require('../assets/images/round-information-outline-orange-icon.png')?.default,
  lg: require('../assets/images/register.jpg')?.default,
  slider: require('../assets/images/lol.jpg')?.default,
  forgotPasswordVerification: require('../assets/images/forgot-password-verification.jpg')?.default,
  forgotPassword: require('../assets/images/forgot-password.jpg')?.default,
  tnc: require('../assets/images/tnc.jpg')?.default,
  banner: require('../assets/images/banner.jpg')?.default,
  LogIn: require('../assets/images/LogIn.jpg')?.default,
  SignUp: require('../assets/images/SignUp.jpg')?.default
}
