import { Flex, Spinner } from '@chakra-ui/react'
import React from 'react'

export default function Loader(): JSX.Element {
  return (
    <Flex justifyContent="center" alignItems="center" width="100%" py={6}>
      <Spinner color="brand.500" size="xl" thickness="4px" speed="0.65s" emptyColor="gray.200" />
    </Flex>
  )
}
