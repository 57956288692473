import { Flex, Image } from '@chakra-ui/react'
import * as React from 'react'
import images from '../../theme/images'
import { H3 } from '../../typography'

type UnderConstructionProps = {
  width?: number | string
  height?: number | string
  message?: string
}

const UnderConstruction: React.FC<UnderConstructionProps> = ({ width, height, message }) => {
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%">
      <Image src={images.onb} width={width} height={height} />
      <H3 textAlign="center" mt={4} fontWeight="lighter">
        {message}
      </H3>
    </Flex>
  )
}

export default UnderConstruction

UnderConstruction.defaultProps = {
  width: 450,
  height: '100%',
  message: 'This page is under construction, check back soon.'
}
