import 'focus-visible/dist/focus-visible'
import * as React from 'react'
import Navigation from './navigation'
import { socket } from 'utils'

const App: React.FC = () => {
  React.useEffect(() => {
    if (!socket.connected) {
      socket.connect()
    }

    return () => {
      socket.off('connect')
      socket.off('disconnect')
      socket.off('new_article')
    }
  }, [])
  return <Navigation />
}
export default App
