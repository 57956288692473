// src/components/CustomTabs.tsx
import React from 'react'
import {
  TabList,
  Tabs,
  useMediaQuery,
  Tab,
  Text,
  TabPanels,
  TabProps,
  TabPanel
} from '@chakra-ui/react'
import { responsiveWidth } from '../../constants'

type TabListProps = {
  text: string
  borderLeftRadius?: number
  borderRightRadius?: number
}

type CustomTabsProps = {
  tabList: TabListProps[]
  tabPanels: React.ReactNode[]
  defaultActiveTab: number
  setActiveTab: (val: number) => void
}

const StyledTab = ({ text, ...rest }: { text: string } & TabProps) => {
  return (
    <Tab
      {...rest}
      width="fit-content"
      height="30px"
      color="white"
      backgroundColor="gray.800"
      _selected={{ color: 'black', bg: 'secondary.550' }}
      borderColor="gray.300"
      borderWidth={1}
      padding={4}
      variant="unstyled"
    >
      <Text fontWeight="bold">{text}</Text>
    </Tab>
  )
}

const CustomTabs: React.FC<CustomTabsProps> = ({
  tabList,
  tabPanels,
  defaultActiveTab,
  setActiveTab
}) => {
  const [isTabletOrMobile] = useMediaQuery(responsiveWidth)
  return (
    <Tabs
      display="flex"
      flexDirection="column"
      width={isTabletOrMobile ? '100%%' : ['100%', '85%', null, null, '60%', '50%']}
      justifyContent="center"
      alignItems="center"
      pt={4}
      size="sm"
      defaultIndex={defaultActiveTab}
      variant="unstyled"
      textAlign="center"
      isFitted={isTabletOrMobile ? true : false}
      isLazy={true}
      onChange={(val) => {
        setActiveTab(val)
      }}
      // my={10}
    >
      <TabList width="fit-content">
        {tabList.map((tab) => (
          <StyledTab
            key={tab.text}
            text={tab.text}
            borderLeftRadius={tab.borderLeftRadius}
            borderRightRadius={tab.borderRightRadius}
          />
        ))}
      </TabList>
      <TabPanels width="100%">
        {tabPanels.map((panel, index) => (
          <TabPanel key={index}>{panel}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

export default CustomTabs
