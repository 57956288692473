import * as React from 'react'

type AppContextType = {
  drawerOpen: boolean
  isShareOpen: boolean
  shareUrl: string
  toggleDrawer: () => void
  setIsShareOpen: (isOpen: boolean) => void
  handleShare: (url: string) => void
}

const MobileOrTabletContext = React.createContext<{ isMobileOrTablet: boolean }>({
  isMobileOrTablet: false
})

export const AppContext = React.createContext<AppContextType>({
  drawerOpen: false,
  isShareOpen: false,
  shareUrl: '',
  toggleDrawer: () => null,
  setIsShareOpen: () => null,
  handleShare: () => null
})

export const useAppContext = (): AppContextType => React.useContext(AppContext)
export const useMobileOrTabletContext = (): { isMobileOrTablet: boolean } =>
  React.useContext(MobileOrTabletContext)

const AppProvider: React.FC<{}> = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [windowWidth, setwindowWidth] = React.useState(window.innerWidth)
  const [isShareOpen, setIsShareOpen] = React.useState(false)
  const [shareUrl, setShareUrl] = React.useState('')
  const [isMobileOrTablet, setIsMobileOrTablet] = React.useState(window.innerWidth < 640)
  const handleResize = () => {
    setwindowWidth(window.innerWidth)
    setIsMobileOrTablet(window.innerWidth < 640)
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [windowWidth])

  function toggleDrawer() {
    setDrawerOpen(!drawerOpen)
  }

  const handleShare = (url: string) => {
    setShareUrl(url)
    setIsShareOpen(true)
  }

  return (
    <MobileOrTabletContext.Provider value={{ isMobileOrTablet }}>
      <AppContext.Provider
        value={{ drawerOpen, toggleDrawer, handleShare, isShareOpen, shareUrl, setIsShareOpen }}
      >
        {children}
      </AppContext.Provider>
    </MobileOrTabletContext.Provider>
  )
}

export default AppProvider
